<template>
  <div>
    <v-card dark tile :loading="loading">
      <v-container fluid class="py-5">
        <v-form ref="form" lazy-validation>
          <v-row align="center">
            <!-- Número -->
            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="service.number"
                :counter="15"
                label="Número"
                hint="Número"
                persistent-hint
                required
                solo-inverted
                :rules="[rules.required, rules.onlyNumbers, rules.counter15]"
              ></v-text-field>
            </v-col>
            <!-- Fecha -->
            <v-col class="d-flex" cols="12" sm="6">
              <v-menu
                v-model="service.date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormatted"
                    label="Fecha"
                    hint="Fecha"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    solo-inverted
                    :rules="[rules.required]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  dark
                  v-model="service.date"
                  @input="date_menu = false"
                  locale="es"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- Cliente -->
            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="service.client"
                :counter="50"
                label="Cliente"
                hint="Cliente"
                persistent-hint
                required
                solo-inverted
                :rules="[rules.required, rules.counter50]"
              ></v-text-field>
            </v-col>
            <!-- ID -->
            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="service.uid"
                :counter="15"
                label="Id de usuario"
                hint="Id de usuario"
                persistent-hint
                required
                solo-inverted
                :rules="[rules.counter15]"
              ></v-text-field>
            </v-col>
            <!-- Dirección -->
            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="service.direction"
                :counter="30"
                label="Dirección"
                hint="Dirección"
                persistent-hint
                required
                solo-inverted
                :rules="[rules.required, rules.counter30]"
              ></v-text-field>
            </v-col>
            <!-- Teléfono -->
            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="service.phone"
                :counter="15"
                label="Teléfono"
                hint="Teléfono"
                persistent-hint
                required
                solo-inverted
                :rules="[rules.counter15]"
              ></v-text-field>
            </v-col>
            <!-- Equipo -->
            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="service.equipment"
                :counter="30"
                label="Equipo"
                hint="Equipo"
                persistent-hint
                required
                solo-inverted
                :rules="[rules.required, rules.counter30]"
              ></v-text-field>
            </v-col>
            <!-- Modelo -->
            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="service.model"
                :counter="20"
                label="Modelo"
                hint="Modelo"
                persistent-hint
                required
                solo-inverted
                :rules="[rules.required, rules.counter20]"
              ></v-text-field>
            </v-col>
            <!-- Serie -->
            <v-col class="d-flex" cols="12" sm="4">
              <v-text-field
                v-model="service.serie"
                :counter="30"
                label="Serie"
                hint="Serie"
                persistent-hint
                required
                solo-inverted
                :rules="[rules.required, rules.counter30]"
              ></v-text-field>
            </v-col>
            <!-- Has -->
            <v-col class="d-flex" cols="12" sm="4">
              <v-text-field
                v-model="service.has"
                :counter="20"
                label="Has"
                hint="Has"
                persistent-hint
                required
                solo-inverted
                :rules="[rules.required, rules.counter20]"
              ></v-text-field>
            </v-col>
            <!-- Servicio realizado: 50hs / 250/750hs / 500hs / 1000hs / 2000hs -->
            <v-col class="d-flex" cols="12" sm="4">
              <v-select
                v-model="service.hours"
                :items="hours_options"
                label="Servicio realizado"
                hint="Servicio realizado"
                persistent-hint
                required
                solo-inverted
                :rules="[rules.required]"
              ></v-select>
            </v-col>
            <!-- Trabajos efectuados (hasta 10 renglones) -->
            <v-col class="d-flex" cols="12">
              <v-textarea
                :value="service.works"
                @change="changeWorks"
                :counter="1000"
                label="Trabajos efectuados"
                hint="Trabajos efectuados"
                persistent-hint
                required
                solo-inverted
                :rules="[rules.required, rules.counter1000, rules.rows20]"
              ></v-textarea>
            </v-col>
            <!-- Repuestos instalados (3 columnas: cantidad, denominación y código) (hasta 20 renglones) -->
            <v-card-title style="width: 100%">
              Repuestos instalados
              <v-spacer></v-spacer>
              <v-btn @click="addReplacement" color="#0049a0"> Agregar </v-btn>
            </v-card-title>
            <v-col
              class="d-flex"
              cols="12"
              v-for="(replacement, index) in replacements"
              :key="'repuesto-' + index"
            >
              <v-row>
                <v-col class="d-flex" cols="12" sm="4">
                  <v-text-field
                    v-model="replacement.cant"
                    label="Cantidad"
                    hint="Cantidad"
                    persistent-hint
                    required
                    solo-inverted
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="4">
                  <v-text-field
                    v-model="replacement.denomination"
                    label="Denominación"
                    hint="Denominación"
                    persistent-hint
                    required
                    solo-inverted
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="3">
                  <v-text-field
                    v-model="replacement.code"
                    label="Código"
                    hint="Código"
                    persistent-hint
                    required
                    solo-inverted
                    :rules="[]"
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="1">
                  <v-btn
                    color="red"
                    @click="removeReplacement(index)"
                    fab
                    x-small
                    dark
                    style="margin-top: 10px"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <!-- Mecánico / Horas (Hasta 5 renglones) -->
            <v-card-title style="width: 100%">
              Mecánicos
              <v-spacer></v-spacer>
              <v-btn @click="addMechanic" color="#0049a0"> Agregar </v-btn>
            </v-card-title>

            <v-col
              class="d-flex"
              cols="12"
              v-for="(mechanic, index) in mechanics"
              :key="'mecanico-' + index"
            >
              <v-row>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-text-field
                    v-model="mechanic.name"
                    label="Nombre"
                    hint="Nombre"
                    persistent-hint
                    required
                    solo-inverted
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="5">
                  <v-text-field
                    v-model="mechanic.hours"
                    label="Horas"
                    hint="Horas"
                    persistent-hint
                    required
                    solo-inverted
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="1">
                  <v-btn
                    color="red"
                    @click="removeMechanic(index)"
                    fab
                    x-small
                    dark
                    style="margin-top: 10px"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt-5 mb-0 mx-1">
            <v-spacer></v-spacer>
            <v-btn
              plain
              :to="{
                name: 'Equipment',
                params: { companyId: companyId, equipmentId: equipmentId },
              }"
            >
              Cancelar
            </v-btn>
            <!-- <v-btn @click="save" color="#0049a0"> Guardar </v-btn> -->
            <v-btn @click="generatePdf" color="#0049a0"> Generar pdf </v-btn>
          </v-row>
        </v-form>
      </v-container>

      <v-overlay :value="uploading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
    <!-- <div style="height: 10px; background-color: #060708"></div> -->
    <v-card dark tile>
      <VueHtml2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="false"
        :paginate-elements-by-height="1600"
        filename="service"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-content-width="800px"
        ref="html2Pdf"
        :image="{
          type: 'jpeg',
          quality: 0.5,
        }"
        :html-to-pdf-options="{
          margin: [25, 5, 5, 5],
        }"
        @beforeDownload="beforeDownload($event)"
      >
        <section slot="pdf-content">
          <Pdf :service="service"></Pdf>
        </section>
      </VueHtml2pdf>
    </v-card>
  </div>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";
import banner from "@/assets/banner_color.png";
import Pdf from "./pdf.vue";
export default {
  name: "Service",
  data: (vm) => ({
    service: {
      number: "",
      client: "",
      uid: "",
      direction: "",
      phone: "",
      equipment: "",
      model: "",
      serie: "",
      has: "",
      hours: "",
      works: "",
      replacements: [],
      mechanics: [],
      date: new Date().toISOString().substr(0, 10),
      month: new Date().toISOString().substr(0, 7),
    },
    result: "",
    hours_options: ["50hs", "250/750hs", "500hs", "1000hs", "2000hs", "Otros"],
    date_menu: false,
    file: null,
    rules: {
      required: (value) => !!value || "Requerido.",
      onlyNumbers: (value) =>
        /^[0-9]+$/.test(value) || "Deben ser un número entero válido",
      counter1000: (value) => value.length <= 1000 || "1000 carácteres máximos",
      counter50: (value) => value.length <= 50 || "50 carácteres máximos",
      counter30: (value) => value.length <= 30 || "30 carácteres máximos",
      counter20: (value) => value.length <= 20 || "20 carácteres máximos",
      counter15: (value) => value.length <= 15 || "15 carácteres máximos",
      rows20: (value) =>
        value.trim().split("\n").length < 20 || "20 saltos de línea máximos",
    },
  }),
  created() {
    this.initData();
  },
  computed: {
    companyId() {
      return this.$route.params.companyId;
    },
    equipmentId() {
      return this.$route.params.equipmentId;
    },
    loading() {
      return this.$store.state.loadingService;
    },
    uploading() {
      return this.$store.state.uploadingService;
    },
    dateFormatted() {
      const date = this.service.date;
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    replacements() {
      if (this.service.replacements) return this.service.replacements;
      return [];
    },
    mechanics() {
      if (this.service.mechanics) return this.service.mechanics;
      return [];
    },
  },
  watch: {
    loading(v) {
      if (v == false) {
        this.service = this.$store.state.selectedService;
      }
    },
    date(v) {
      this.service.month = v.substr(0, 7);
    },
  },
  methods: {
    addReplacement() {
      if (!this.service.replacements) this.service.replacements = [];
      if (this.service.replacements.length < 20)
        this.service.replacements.push({
          cant: "",
          denomination: "",
          code: "",
        });
    },
    removeReplacement(index) {
      this.service.replacements.splice(index, 1);
    },
    addMechanic() {
      if (!this.service.mechanics) this.service.mechanics = [];
      if (this.service.mechanics.length < 5)
        this.service.mechanics.push({ name: "", hours: "" });
    },
    removeMechanic(index) {
      this.service.mechanics.splice(index, 1);
    },
    changeWorks(value) {
      this.service.works = value;
    },
    save() {
      if (this.$refs.form.validate()) {
        let data = {};
        data.service = this.service;
        data.service.equipmentId = this.$route.params.equipmentId;
        data.service.companyId = this.$route.params.companyId;
        data.serviceId = this.$route.params.serviceId;
        data.file = this.file;
        this.$store.dispatch("updateService", data);
      }
    },
    initData() {
      if (!!this.$route.params.serviceId) {
        this.$store.dispatch("selectService", this.$route.params.serviceId);
      }
    },
    generatePdf() {
      this.$refs.html2Pdf.generatePdf();
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      if (this.$refs.form.validate()) {
        const banner = require("../assets/banner_color.png");
        await html2pdf()
          .set(options)
          .from(pdfContent)
          .toPdf()
          .get("pdf")
          .then(async (pdf) => {
            const totalPages = pdf.internal.getNumberOfPages();
            for (let i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              const width = pdf.internal.pageSize.getWidth();
              const height = pdf.internal.pageSize.getHeight();
              pdf.addImage(banner, "PNG", 5, 0, 80, 20, "", "SLOW");
              pdf.setFillColor("#0049a0");
              pdf.setLineWidth(0.05);
              pdf.line(2.5, 20.8, width - 2.5, 20.8, "F");
              pdf.line(2.5, 20.8, 2.5, height - 10, "F");
              pdf.line(width - 2.5, 20.8, width - 2.5, height - 10, "F");
              pdf.line(2.5, height - 10, width - 2.5, height - 10, "F");
              pdf.setFontSize(10);
              pdf.setTextColor("0049a0");
              pdf.text(
                "Página " + i + " de " + totalPages,
                width - 24,
                height - 5
              );
            }
            this.file = pdf.output("blob");
            this.save();
            // this.result = pdf.output("bloburl");
            //   pdf.autoPrint();
            // window.open(pdf.output("bloburl"));
          });
      }
    },
  },
  components: {
    VueHtml2pdf,
    Pdf,
  },
};
</script>

<style>
iframe {
  border: 0;
}
</style>