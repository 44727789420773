<template>
  <div>
    <div class="row-pdf">
      <label style="flex: auto; font-weight: bold; font-size: 24px">
        CONFORME DE SERVICIO
      </label>
      <label style="flex: auto">N° - {{ service.number }}</label>
      <label style="flex: auto; text-align: right">FECHA: {{ service.date }}</label>
    </div>
    <div style="height: 10px"></div>
    <div class="row-pdf">
      <div class="big-item-pdf">
        <label> CLIENTE </label>
        <label>{{ service.client }}</label>
      </div>
      <div class="small-item-pdf">
        <label> ID </label>
        <label>{{ service.uid }}</label>
      </div>
    </div>
    <div class="row-pdf">
      <div class="big-item-pdf">
        <label> DIRECCIÓN </label>
        <label>{{ service.direction }}</label>
      </div>
      <div class="small-item-pdf">
        <label> TEL </label>
        <label>{{ service.phone }}</label>
      </div>
    </div>
    <div class="row-pdf">
      <div class="big-item-pdf">
        <label> EQUIPO </label>
        <label>{{ service.equipment }}</label>
      </div>

      <div class="small-item-pdf">
        <label> MODELO </label>
        <label>{{ service.model }}</label>
      </div>
    </div>
    <div class="row-pdf">
      <div class="big-item-pdf">
        <label> SERIE </label>
        <label>{{ service.serie }}</label>
      </div>

      <div class="small-item-pdf">
        <label> HAS </label>
        <label>{{ service.has }}</label>
      </div>
    </div>
    <div class="row-pdf">
      <label class="title-item"> SERVICIO REALIZADO </label>
      <label class="data-item">{{ service.hours }}</label>
    </div>
    <div class="row-pdf">
      <label class="title-item"> TRABAJOS EFECTUADOS </label>
    </div>
    <div class="row-pdf">
      <label class="multi-line-item-pdf">
        <label v-for="work in worksBreakLine">  {{ work }} </br></label>
      </label>
    </div>
    <div class="html2pdf__page-break"/>
    <div class="row-pdf" v-if="hasReplacements">
      <label class="title-item"> REPUESTOS INSTALADOS </label>
    </div>
    <div class="row-pdf" v-if="hasReplacements">
      <table>
        <thead>
          <tr>
            <th>Cantidad</th>
            <th>Denominación</th>
            <th>Código</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(replacement, index) in service.replacements" :key="'repuesto-pdf-'+index">
            <td>{{replacement.cant || '-'}}</td>
            <td>{{replacement.denomination || '-'}}</td>
            <td>{{replacement.code || '-'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row-pdf" v-if="hasMechanics">
      <label class="title-item">MECÁNICOS</label>
    </div>
    <div class="row-pdf" v-if="hasMechanics">
      <table>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Horas</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(mechanic, index) in service.mechanics" :key="'mecanico-pdf-'+index">
            <td>{{mechanic.name || '-'}}</td>
            <td>{{mechanic.hours || '-'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pdf",
  props: {
    service: {},
  },
  computed: {
    worksBreakLine() {
      if (!this.service.works) return [];
      return this.service.works.trim().split("\n");
    },
    hasReplacements() {
      if (this.service.replacements)
        return this.service.replacements.length > 0;
      return false;
    },
    hasMechanics() {
      if (this.service.mechanics) return this.service.mechanics.length > 0;
      return false;
    },
  },
};
</script>

<style>
.row-pdf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 756px;
  margin-bottom: 20px;
  color: black;
  font-size: 18px;
}

.big-item-pdf {
  width: 504px;
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
}
.small-item-pdf {
  width: 252px;
  display: flex;
  justify-content: space-between;
}

.big-item-pdf :first-child,
.small-item-pdf :first-child,
.title-item {
  font-weight: bold;
  flex: 0 1 auto;
}
.big-item-pdf :last-child,
.small-item-pdf :last-child,
.data-item {
  text-align: right;
  flex: 1 1 auto;
  color: #0049a0;
  border-bottom: 1px dashed #0049a0;
}

.multi-line-item-pdf {
  color: #0049a0;
  border: 1px dashed #0049a0;
  padding: 5px;
  flex: 1 1 auto;
}

.row-pdf table,
.row-pdf th,
.row-pdf td {
  border: 1px solid;
  border-collapse: collapse;
}

.row-pdf table {
  width: 100%;
}

.row-pdf th,
.row-pdf td {
  text-align: center;
}
</style>